import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText, PrismicRichText } from '@prismicio/react'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { FaTwitter, FaFacebookF, FaInstagram} from 'react-icons/fa'

export const BottomMenu = ({menu}) => {
  const [status, setStatus] = React.useState(null);
  
  // Extract Mailchimp URL from the endpoint in gatsby-config.js
  // https://tigweb.us16.list-manage.com/subscribe/post?u=c1632d380752880224009f98c&amp;id=77396d7f73
  const mailchimpUrl = "https://tigweb.us16.list-manage.com/subscribe/post?u=c1632d380752880224009f98c&id=77396d7f73";
  return (
    <div className="BottomMenu">
      <section className="Newsletter">
        <div className="Container">
          <div className="flex-wrap">
            <div className="copy-wrap w-5">
              <PrismicRichText
                field={menu.newsletter_richtext?.richText}
              />
              <form 
                action={mailchimpUrl}
                method="post"
                name="mc-embedded-subscribe-form"
                target="_blank"
                onSubmit={() => setStatus("submitting")}
              >
                <input 
                  type="email" 
                  name="EMAIL" 
                  placeholder={menu.input_label}
                  required
                />
                {/* Bot protection - real people should not fill this in */}
                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                  <input type="text" name="b_c1632d380752880224009f98c_77396d7f73" tabIndex="-1" />
                </div>
                <input 
                  type="submit" 
                  className="btn-blue" 
                  value={menu.submit_label}
                  name="subscribe"
                />
              </form>
              {status === "submitting" && <p>Subscribing...</p>}
            </div>
            <div className="image-wrap">
              <GatsbyImage
                image={menu.newsletter_image?.gatsbyImageData}
                alt=""
                role="presentation"
              />
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="center-wrap">
          <PrismicLink href={menu.homepage_link?.url}>
            <GatsbyImage
              image={menu.logo?.gatsbyImageData}
              alt={menu.logo?.alt || ""}
            />
          </PrismicLink>
          <div className="menu_links">
            {menu.footer_links.map((item,index) => (
              <PrismicLink href={item.link?.url} key={`footerLink: ${index}`}>
                {item.link_label}
              </PrismicLink>
            ))}
          </div>
          <div className="social_links">
            <PrismicLink href={menu.twitter?.url}>
              <p className="visually-hidden">Twitter</p>
              <FaTwitter/>
            </PrismicLink>
            <PrismicLink href={menu.facebook?.url}>
              <p className="visually-hidden">Facebook</p>
              <FaFacebookF/>
            </PrismicLink>
            <PrismicLink href={menu.instagram?.url}>
              <p className="visually-hidden">Instagram</p>
              <FaInstagram/>
            </PrismicLink>
          </div>
          <div className="trademark">
            <PrismicLink href="https://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank">
              <StaticImage
                src="../images/cc.png"
                alt="Creative Commons License"
                className="cc"
                width={88}
              />
            </PrismicLink>
          </div>
        </div>
      </footer>
    </div>
  )
}

export const query = graphql`
  fragment BottomMenuFragment on PrismicMenu {
    _previewable
    type
    lang
    data {
      logo {
        gatsbyImageData(
          width: 300
          imgixParams: {q: 100}
        )
        alt 
      }
      homepage_link {
        url
      }
      trademark
      twitter {
        url
      }
      facebook {
        url
      }
      instagram {
        url
      }
      newsletter_richtext {
        richText
      }
      submit_label
      input_label
      newsletter_image {
        gatsbyImageData(width: 394)
        alt
      }
      footer_links {
        link {
          url 
        }
        link_label
      }
    }
  }
`
